import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";

import { setSearchText, setDirty, setSuccess } from "store/general";
import { renderValue } from 'common/functions';
import { setValues, updateRequest, reset } from "../store/settings";
import { GetASRSCageBatchSetting, CreateOrUpdateASRSCageBatchSetting } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/asrsStyle.js";

const useStyles = makeStyles(styles);

export default function Settings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const setting = useSelector(store => store.user.asrs.settings.setting);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateASRSCageBatchSetting())
      .then(({error})=> {
        if (!error) {
          dispatch(setDirty(false));
          dispatch(GetASRSCageBatchSetting());
          dispatch(setValues({setting: {}}));
          dispatch(setSuccess('Number of batches have been successfully modified'));
        }
      })
    }
  }

  const validateFields = () => {
    if (!setting.numberOfBatch) {
      setErrorMsg({field: "numberOfBatch", msg: "Please enter a value"});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetASRSCageBatchSetting());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: classes.settingsCard
        }}
        title = "Settings"
      >
        <Paper elevation={0} className={classes.settingPaper}>
          <Grid container spacing={4} className={classes.settingContainer}>
            <Grid item xs={4} className={classes.settingLabel}>
              Split Into Batches Of:
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="numberOfBatch"
                variant="outlined" 
                type="number"
                onInput={(e)=>{ 
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,5)
                }}
                placeholder="Enter value"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(setting.numberOfBatch)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={12} className={classes.settingButton}>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>location.reload()}
              >
                Cancel
              </Button>
              <Button 
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Card>
    </React.Fragment>
  );
}
